import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import Carousel from '../components/Carousel'

// Query for the Discipline content in Prismic
export const query = graphql`
query Discipline {
  allPrismicDiscipline{
    edges{
      node{
        id
        uid
        type
        data {
          name {
            html
            text
            raw
          }
          detail {
            html
            text
            raw
          }
          images {
            image {
              alt
              copyright
              url
            }
          }
        }
      }
    }
  }
}
`

export default (props) => {
  // Define the Discipline content returned from Prismic
  const doc = props.data.allPrismicDiscipline.edges.filter(discipline => discipline.node.uid === props.pageContext.uid)[0];

  if(!doc) return null;

  const discipline = doc.node.data;

  return(
    <Layout title={ discipline.name.text }>
      <div>
        <div className="pure-u-md-1-2 pure-u-1-1 post-header">
          <Carousel title={ discipline.name.text } images={ discipline.images.map(i => i.image) } />
        </div>
        <div className="pure-u-md-1-2 pure-u-1-1 post-header">
          <div dangerouslySetInnerHTML={{__html: discipline.detail.html }} />
        </div>
      </div>
    </Layout>
  )
}
